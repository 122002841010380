import FeaturedServiceImg1 from "../../Images/1.jpg";
import FeaturedServiceImg2 from "../../Images/8.jpg";
import FeaturedServiceImg3 from "../../Images/9.jpg";

import C1B1Img1 from "./ProductImages/Category1Imgs/C1Prod1/DH logo.jpg"
import C1B1Img2 from "./ProductImages/Category1Imgs/C1Prod1/cx1- F2-Arcadience-Architects.jpg"
import C1B1Img3 from "./ProductImages/Category1Imgs/C1Prod1/Coffee logo 2.jpg"
import C1B1Img4 from "./ProductImages/Category1Imgs/C1Prod1/DStreat.jpg"
import C1B1Img5 from "./ProductImages/Category1Imgs/C1Prod1/Trimedi Centre logo.jpg"
import C1B1Img6 from "./ProductImages/Category1Imgs/C1Prod1/Market Gabru.jpg"
import C1B1Img7 from "./ProductImages/Category1Imgs/C1Prod1/Punjab House.jpg"
import C1B1Img8 from "./ProductImages/Category1Imgs/C1Prod1/Justitia.jpg"

import C1B2Img1 from "./ProductImages/Category1Imgs/C1Prod2/MarketGabru.jpg"
import C1B2Img2 from "./ProductImages/Category1Imgs/C1Prod2/Brand.jpg"
import C1B2Img3 from "./ProductImages/Category1Imgs/C1Prod2/HermesLaw.jpg"

import C1B3Img1 from "./ProductImages/Category1Imgs/C1Prod3/DripCast podcast.jpg"
import C1B3Img2 from "./ProductImages/Category1Imgs/C1Prod3/Advocate Mratyunjay-min.jpg"
import C1B3Img3 from "./ProductImages/Category1Imgs/C1Prod3/Arcadience Architects-min.jpg"
import C1B3Img4 from "./ProductImages/Category1Imgs/C1Prod3/Media Master-min.jpg"
import C1B3Img5 from "./ProductImages/Category1Imgs/C1Prod3/Urban Trendz-min.jpg"
import C1B3Img6 from "./ProductImages/Category1Imgs/C1Prod3/Shelter Heavens-min.jpg"
import C1B3Img7 from "./ProductImages/Category1Imgs/C1Prod3/Sunshine beauty Saloon-min.jpg"
import C1B3Img8 from "./ProductImages/Category1Imgs/C1Prod3/Trendify-min.jpg"
import C1B3Img9 from "./ProductImages/Category1Imgs/C1Prod3/Oven Fresh Delight-min.jpg"
import C1B3Img10 from "./ProductImages/Category1Imgs/C1Prod3/Vishal Navratan-min.jpg"
import C1B3Img11 from "./ProductImages/Category1Imgs/C1Prod3/Kamal Yoga Academy-min.jpg"

import C1B4Img1 from "./ProductImages/Category1Imgs/C1Prod4/Hermes Law 1.jpg"
import C1B4Img2 from "./ProductImages/Category1Imgs/C1Prod4/Trimedi Center Letter Head.jpg"
import C1B4Img3 from "./ProductImages/Category1Imgs/C1Prod4/Hermes law Main1.jpg"

import C1P5Img1 from "./ProductImages/Category1Imgs/C1Prod5/Dezaina Signage.jpg"
import C1P5Img2 from "./ProductImages/Category1Imgs/C1Prod5/HomeTown Furniture.jpg"
import C1P5Img3 from "./ProductImages/Category1Imgs/C1Prod5/Saraswathi Library.jpg"



import C2B1Img1 from "./ProductImages/Category2Imgs/C2Prod1/BhartiMakeover.jpg"
import C2B1Img2 from "./ProductImages/Category2Imgs/C2Prod1/Deva Resume1.jpg"
import C2B1Img3 from "./ProductImages/Category2Imgs/C2Prod1/Zeal1.jpg"
import C2B1Img4 from "./ProductImages/Category2Imgs/C2Prod1/Sachin Resume.jpg"

import C2B2Img1 from "./ProductImages/Category2Imgs/C2Prod2/Web UI1.png"
import C2B2Img2 from "./ProductImages/Category2Imgs/C2Prod2/DezainaHub Main.jpg"
import C2B2Img3 from "./ProductImages/Category2Imgs/C2Prod2/AnilBimal Law Firm.jpg"
// import C2B2Img4 from "./ProductImages/Category2Imgs/C2Prod2/8817350.jpg"

import C2B3Img1 from "./ProductImages/Category2Imgs/C2Prod3/App UI1.jpeg"
import C2B3Img2 from "./ProductImages/Category2Imgs/C2Prod3/c2b3i2.png"

import C2B4Img1 from "./ProductImages/Category2Imgs/C2Prod4/Brand.jpg"

import C2B5Img1 from "./ProductImages/Category2Imgs/C2Prod5/Gaming Event 2.jpg"
import C2B5Img2 from "./ProductImages/Category2Imgs/C2Prod5/Neurolingua.jpg"






import C3B1Img1 from "./ProductImages/Category3Imgs/C3Prod1/Banjaarey.jpg"
import C3B1Img2 from "./ProductImages/Category3Imgs/C3Prod1/Cofee Flower.jpg"
import C3B1Img3 from "./ProductImages/Category3Imgs/C3Prod1/Football.jpg"

import C3B2Img1 from "./ProductImages/Category3Imgs/C3Prod2/Bag House.jpg"
import C3B2Img2 from "./ProductImages/Category3Imgs/C3Prod2/Faishon.jpg"
import C3B2Img3 from "./ProductImages/Category3Imgs/C3Prod2/Coffee banner.jpg"
import C3B2Img4 from "./ProductImages/Category3Imgs/C3Prod2/DPunjab House.jpg"

import C3B3Img1 from "./ProductImages/Category3Imgs/C3Prod3/Summer.jpg"
import C3B3Img2 from "./ProductImages/Category3Imgs/C3Prod3/Coffee.jpg"
import C3B3Img3 from "./ProductImages/Category3Imgs/C3Prod3/Flowerr.jpg"

import C3B4Img1 from "./ProductImages/Category3Imgs/C3Prod4/Bi Waffin.jpg"
import C3B4Img2 from "./ProductImages/Category3Imgs/C3Prod4/Z-fold Coffee.jpg"
import C3B4Img3 from "./ProductImages/Category3Imgs/C3Prod4/HermesLaw Trifold Broucher.jpg"

import C3B5Img1 from "./ProductImages/Category3Imgs/C3Prod5/Punjab House.jpg"
import C3B5Img2 from "./ProductImages/Category3Imgs/C3Prod5/MessMenu.jpg"
import C3B5Img3 from "./ProductImages/Category3Imgs/C3Prod5/DStreat.jpg"

import C3B6Img1 from "./ProductImages/Category3Imgs/C3Prod6/Gym m.jpg"
import C3B6Img2 from "./ProductImages/Category3Imgs/C3Prod6/Festives.jpg"
import C3B6Img3 from "./ProductImages/Category3Imgs/C3Prod6/Brand.jpg"
import C3B6Img4 from "./ProductImages/Category3Imgs/C3Prod6/Food.jpg"
import C3B6Img5 from "./ProductImages/Category3Imgs/C3Prod6/AnimalDay.jpg"



const ProductDataC1 = [
  // Logo Design 
  {
    Name: "Logo Design",
    minPrice: "2000",
    maxPrice: "5000",
    gallery: [
      {
        img: C1B1Img1,
      },
      {
        img: C1B1Img2,
      },
      {
        img: C1B1Img3,
      },
      {
        img: C1B1Img4,
      },
      {
        img: C1B1Img5,
      },
      {
        img: C1B1Img6,
      },
      {
        img: C1B1Img7,
      },
      {
        img: C1B1Img8,
      },],
    Description: {
      DescriptHead:
        "A professionally designed logo is the visual cornerstone of your brand. It's essential for creating a memorable and recognizable brand identity.",

      DescriptFactors: [
        { factorsPoints: "Complexity of the design." },
        { factorsPoints: "Number of initial concepts provided." },
        { factorsPoints: "Revisions included." },
        { factorsPoints: "Brand Color Palate." },
        { factorsPoints: "Turnaround time." },
      ],

      DescriptFooter: [{ FooterPoints: "" }],
    },

    featured: true,
  },
  // Brand kit design 
  {
    Name: "Brand Kit Design",
    minPrice: "1000",
    maxPrice: "5000",
    gallery: [{
      img: C1B2Img1,
    },{
      img: C1B2Img2,
    },{
      img: C1B2Img3,
    },],
    Description: {
      DescriptHead:
        "Brand kit includes brand guidelines, color palette, typography, orientation and other related details. A brand kit provides a clear set of guidelines for consistent use of your brand's visual elements, ensuring a cohesive and professional image across all materials.",

      DescriptFactors: [
        { factorsPoints: "The comprehensiveness of the brand guidelines" },
        { factorsPoints: "Number of initial concepts provided" },
        { factorsPoints: "Revisions included." },
        { factorsPoints: "Designer's expertise" },
        { factorsPoints: "Single Page or a Brief Kit" },
      ],

      DescriptFooter: [{ FooterPoints: "" }],
    },
    featured: true,
  },
  // Business Card design 
  {
    Name: "Business Card Design",
    minPrice: "500",
    maxPrice: "2000",
    gallery: [{
      img: C1B3Img1,
    },{
      img: C1B3Img2,
    },{
      img: C1B3Img3,
    },{
      img: C1B3Img4,
    },{
      img: C1B3Img5,
    },{
      img: C1B3Img6,
    },{
      img: C1B3Img7,
    },{
      img: C1B3Img8,
    },{
      img: C1B3Img9,
    },{
      img: C1B3Img10,
    },{
      img: C1B3Img11,
    },],
    Description: {
      DescriptHead:
        "Well-designed business/Visiting cards are crucial for leaving a lasting impression on potential clients and partners. They contain essential contact information and reflect your brand's personality.",

      DescriptFactors: [
        { factorsPoints: "Complexity of the design." },
        { factorsPoints: "Number of initial concepts provided." },
        { factorsPoints: "Revisions included." },
      ],

      DescriptFooter: [{ FooterPoints: "" }],
    },
    featured: true,
  },
  // letter head design 
  {
    Name: "Letter Head Design",
    minPrice: "300",
    maxPrice: "1000",
    gallery: [{
      img: C1B4Img1,
    },{
      img: C1B4Img2,
    },{
      img: C1B4Img3,
    },],
    Description: {
      DescriptHead:
        " Letterheads with a professional design enhance official correspondence and reinforce your brand's identity when sending formal documents.",

      DescriptFactors: [
        { factorsPoints: "Complexity of the design." },
        { factorsPoints: "Number of initial concepts provided." },
        { factorsPoints: "Revisions included." },
      ],

      DescriptFooter: [{ FooterPoints: "" }],
    },
    featured: false,
  },
  // signage Design
  {
    Name: "Signage Design",
    minPrice: "300",
    maxPrice: "1000",
    gallery: [{
      img: C1P5Img1,
    }, {
      img: C1P5Img2,
    }, {
      img: C1P5Img3,
    },],
    Description: {
      DescriptHead:
        "Signage design ensures that your brand is prominently displayed, making it easy for customers and visitors to locate and identify your business.",

      DescriptFactors: [
        { factorsPoints: "Complexity of the design." },
        { factorsPoints: "Number of initial concepts provided." },
        { factorsPoints: "Revisions included." },
      ],

      DescriptFooter: [{ FooterPoints: "" }],
    },
    featured: false,
  },
];

// gallery need link object after img 

const ProductDataC2 = [
  // portfolio design 
  {
    Name: "Portfolio Design",
    minPrice: "500",
    maxPrice: "3000",
    gallery: [
      {
        img: C2B1Img1,
      },{
        img: C2B1Img2,
      },{
        img: C2B1Img3,
      },{
        img: C2B1Img4,
      }],
    Description: {
      DescriptHead:
        "A professionally designed portfolio showcases your work, making it easier to attract clients or employers and highlight your skills.",

      DescriptFactors: [
        { factorsPoints: "Number of portfolio items and content" },
        { factorsPoints: "Complexity of design" },
        { factorsPoints: "Custom graphics and branding." },
        { factorsPoints: "Revision Included " },
      ],

      DescriptFooter: [{ FooterPoints: "" }],
    },
    featured: true,
  },
  // website design 
  {
    Name: "Website Design",
    minPrice: "2000",
    maxPrice: "12000",
    gallery: [
      {
        img: C2B2Img1,
        link: 'https://www.figma.com/proto/KBr8U09l3o90njbZ4Riptj/Restrosol-Web-design?page-id=0%3A1&node-id=1-816&viewport=3665%2C3079%2C0.3&t=ZoUaZsG3SyBFHldl-1&scaling=min-zoom&content-scaling=fixed&starting-point-node-id=84%3A14778 ',
      },
      {
        img: C2B2Img2,
        link: 'https://www.figma.com/proto/OqgCPKgOtzEwR41pTJa3xh/Finance-Web-UI?page-id=0%3A1&node-id=13-17&viewport=1539%2C849%2C0.2&t=zycJugIq6oExomFp-1&scaling=min-zoom&content-scaling=fixed',
      },
      {
        img: C2B2Img3,
        link: 'https://www.figma.com/proto/Iaeq3iKFI36dIZx4fATgHb/E-commerce-Website-UI?page-id=0%3A1&node-id=20-2&t=ENH3IJPm7T7RpXGS-1',
      },
      // WIP: removed becz of anavailable figma link can be added afterwards 
      // for adding refer to the imported image 
      // {
      //   img: C2B2Img4,
      // }
    ],
    Description: {
      DescriptHead:
        "A well-designed website is crucial for establishing an online presence, attracting customers, and providing information about your brand.",

      DescriptFactors: [
        { factorsPoints: "Animations" },
        { factorsPoints: "Complexity of design and functionality." },
        { factorsPoints: "Custom features (Responsive Website)" },
        { factorsPoints: "Revision Included " },
        { factorsPoints: "Turnaround time." },
      ],

      DescriptFooter: [
        { FooterPoints: "For Static page - @ ₹2000 per page" },
        { FooterPoints: "For 3 Pages - @ ₹̶6̶0̶0̶0̶  - ₹5400 " },
        { FooterPoints: "For 5 pages - @ ̶₹̶1̶0̶0̶0̶0̶ - ₹7500" },
        { FooterPoints: "For 10 Pages - @  ̶₹̶2̶0̶0̶0̶0̶ - ₹12,000" },
      ],
    },
    featured: true,
  },
  // application design 
  {
    Name: "Application Design",
    minPrice: "2000",
    maxPrice: "12000",
    gallery: [
      {
        img: C2B3Img1,
        link: ' https://www.figma.com/proto/FmAC6IAcxbmL0D5yMi6p6P/Coffee-Shop-Mobile-App-Design-(Community)?page-id=2%3A2&node-id=202-291&viewport=325%2C74%2C0.89&t=6CpYVkiOcL7IAxat-1&scaling=scale-down&content-scaling=fixed&starting-point-node-id=202%3A266',
      },
      {
        img: C2B3Img2,
        link: 'https://www.figma.com/proto/Cuc64nvDgEfnkKywiZQvSm/Job-Finder-Ui-App-Kit-(Community)?page-id=1%3A48&node-id=1-116&starting-point-node-id=1%3A116&scaling=scale-down&content-scaling=fixed&show-proto-sidebar=1&t=XXuM7kJYq4447NiH-1 ',
      },
    ],
    Description: {
      DescriptHead:
        "App design focuses on creating user-friendly interfaces and experiences for your digital products, enhancing user engagement.",

      DescriptFactors: [
        { factorsPoints: "Animations" },
        { factorsPoints: "Complexity of design and functionality." },
        { factorsPoints: "Custom features (Responsive Website)" },
        { factorsPoints: "Revision Included " },
        { factorsPoints: "Turnaround time." },
      ],

      DescriptFooter: [
        { FooterPoints: "For Static page - @ ₹2000 per page" },
        { FooterPoints: "For 3 Pages - @ ₹̶6̶0̶0̶0̶  - ₹5400 " },
        { FooterPoints: "For 5 pages - @ ̶₹̶1̶0̶0̶0̶0̶ - ₹7500" },
        { FooterPoints: "For 10 Pages - @  ̶₹̶2̶0̶0̶0̶0̶ - ₹12,000" },
      ],
    },
    featured: true,
  },
  // Presentaion Design 
  {
    Name: "Presentation Design",
    minPrice: "250",
    maxPrice: "500",
    gallery: [{img: C2B4Img1}] ,
    Description: {
      DescriptHead:
        "Professional presentations can make a significant impact during meetings, pitches, or conferences, conveying your message effectively.",

      DescriptFactors: [
        { factorsPoints: "Number of slides." },
        { factorsPoints: "Complexity of design." },
        { factorsPoints: "Custom graphics and animations." },
        { factorsPoints: "Revision Included " },
      ],

      DescriptFooter: [
        { FooterPoints: "For 3 or more slides - starting @ ₹500 per slide" },
        { FooterPoints: "For 5 or more slides - starting @ ₹400 per slide" },
        { FooterPoints: "For 10 or more slides - starting @ ₹300 per slide" },
        { FooterPoints: "For 15 or more slides - starting @ ₹250 per slide" },
      ],
    },
    featured: false,
  },
  // Pitch Deck Design 
  {
    Name: "Pitch Deck Design",
    minPrice: "250",
    maxPrice: "500",
    gallery: [{img: C2B5Img1}, {img: C2B5Img2}],
    Description: {
      DescriptHead:
        "A well-crafted pitch deck is essential for impressing investors, partners, or clients, helping you secure opportunities and funding.",

      DescriptFactors: [
        { factorsPoints: "Number of slides." },
        { factorsPoints: "Complexity of design." },
        { factorsPoints: "Custom graphics and animations." },
        { factorsPoints: "Revision Included " },
      ],

      DescriptFooter: [
        { FooterPoints: "For 3 or more slides - starting @ ₹500 per slide" },
        { FooterPoints: "For 5 or more slides - starting @ ₹400 per slide" },
        { FooterPoints: "For 10 or more slides - starting @ ₹300 per slide" },
        { FooterPoints: "For 15 or more slides - starting @ ₹250 per slide" },
      ],
    },
    featured: false,
  },
  
];




const ProductDataC3 = [
  {
    Name: "Poster Design",
    minPrice: "500",
    maxPrice: "2500",
    gallery: [
      {
        img: C3B1Img1,
      },{
        img: C3B1Img2,
      },{
        img: C3B1Img3,
      }],
    Description: {
      DescriptHead:
        "Posters are powerful marketing tools for events, promotions, or showcasing your brand's message and products to a broader audience.",

      DescriptFactors: [
        { factorsPoints: "Size of the poster" },
        { factorsPoints: "Complexity of the poster" },
        { factorsPoints: "Customization and graphic elements." },
        { factorsPoints: "Revisions Included" },
      ],

      DescriptFooter: [{ FooterPoints: "" }],
    },

    featured: false,
  },
  {
    Name: "Banner Design",
    minPrice: "500",
    maxPrice: "2500",
    gallery: [{
      img: C3B2Img1,
    },{
      img: C3B2Img2,
    },{
      img: C3B2Img3,
    },{
      img: C3B2Img4,
    },],
    Description: {
      DescriptHead:
        "Banners are excellent for outdoor or indoor advertising, helping to grab the attention of potential customers and conveying key messages effectively.",

      DescriptFactors: [
        { factorsPoints: "Size of the banner" },
        { factorsPoints: "Complexity of the banner" },
        { factorsPoints: "Customization and graphic elements." },
        { factorsPoints: "Revisions Included" },
      ],

      DescriptFooter: [{ FooterPoints: "" }],
    },
    featured: true,
  },
  {
    Name: "Flyer Design",
    minPrice: "500",
    maxPrice: "3000",
    gallery: [{
      img: C3B3Img1,
    },{
      img: C3B3Img2,
    },{
      img: C3B3Img3,
    },],
    Description: {
      DescriptHead:
        "Flyers are versatile marketing materials used for promoting special offers, events, or introducing new products or services.",

      DescriptFactors: [
        { factorsPoints: "Single-sided or double-sided design" },
        { factorsPoints: "Orientation" },
        { factorsPoints: "Customization and graphic elements." },
        { factorsPoints: "Revisions Included" },
      ],

      DescriptFooter: [{ FooterPoints: "" }],
    },
    featured: false,
  },
  {
    Name: "Brochure Design",
    minPrice: "500",
    maxPrice: "2000",
    gallery: [
      {
        img: C3B4Img1,
      },{
        img: C3B4Img2,
      },{
        img: C3B4Img3,
      }, ],
    Description: {
      DescriptHead:
        "Brochure are Double Sided informative marketing materials, ideal for detailing product or service offerings and establishing credibility.",

      DescriptFactors: [
        {
          factorsPoints: "Type of Brochure (Trifold , bifold , z fold etc..,)",
        },
        { factorsPoints: "Number of pages and complexity" },
        { factorsPoints: "Customization and graphic elements." },
        { factorsPoints: "Revisions Included" },
      ],

      DescriptFooter: [{ FooterPoints: "" }],
    },
    featured: true,
  },
  {
    Name: "Catalog Design",
    minPrice: "300",
    maxPrice: "1000",
    gallery: [{
      img: C3B5Img1,
    },{
      img: C3B5Img2,
    },{
      img: C3B5Img3,
    },],
    Description: {
      DescriptHead:
        "Catalogs are comprehensive sales tools that help showcase your product range and assist customers in making informed purchase decisions.",

      DescriptFactors: [
        { factorsPoints: "Catalog size and complexity." },
        { factorsPoints: "Number of pages and complexity" },
        { factorsPoints: "Number of products featured." },
        { factorsPoints: "Customization and graphics." },
        { factorsPoints: "Revisions Included" },
      ],

      DescriptFooter: [
        { FooterPoints: "For Single page - starting @ ₹500 per page " },
        {
          FooterPoints:
            "For 5 or more slides - starting @ ₹400 per page after 5th page ",
        },
        {
          FooterPoints:
            "For 10 or more slides - starting @ ₹300 per page after 10th page",
        },
      ],
    },
    featured: true,
  },
  {
    Name: "Social Media Design",
    minPrice: "300",
    maxPrice: "1000",
    gallery: [{
      img: C3B6Img1,
    }, {
      img: C3B6Img2,
    }, {
      img: C3B6Img3,
    }, {
      img: C3B6Img4,
    }, {
      img: C3B6Img5,
    }, ],
    Description: {
      DescriptHead:
        "Engaging social media graphics are essential for maintaining an active online presence and effectively conveying your brand's message to your target audience.",

      DescriptFactors: [
        { factorsPoints: "Number of graphics required." },
        { factorsPoints: "Customization and branding." },
        {
          factorsPoints:
            "Number of pages and complexity (single post or carousel post)",
        },
        { factorsPoints: "Revisions Included" },
      ],

      DescriptFooter: [{ FooterPoints: "" }],
    },
    featured: false,
  },
];

export default ProductDataC1;
export { ProductDataC2, ProductDataC3 };
